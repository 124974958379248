import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { Scene, Vector3, DirectionalLightHelper, CameraHelper } from 'three';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import { OrbitControls, Box, useHelper, PerspectiveCamera, Stats, Text, Html, Line, Edges } from '@react-three/drei';
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useControls, button, buttonGroup } from "leva";
import { date } from '@leva-ui/plugin-dates'
import { MAPCONFIG} from './mapConfig3.js';
import { throttle } from 'lodash';

import './index.css';

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function randomColor() {
  var randomColor = Math.floor(Math.random()*16777215).toString(16);
  return randomColor;
}

const ColladaTest = ({map}) => {
  const { scene }  = useLoader(ColladaLoader, map);
   return(
    <primitive object={scene} dispose={null} />
  )
}

/*
<lineSegments>
        <edgesGeometry args={[new BoxGeometry(size[0], size[1], size[2])]} />
        <lineBasicMaterial
          color={renderGroupColors()?.primary}
          linewidth={3}
        />
      </lineSegments>
*/
const Zone = ({color, points, floor, visible}) => {
  const floorHeight = {
    "1" : 1.5,
    "2" : 5.5, //height of floor - minfloor height / 2 + minfloor height
  }

  let xSize = points[2].x - points[0].x;
  let ySize = points[2].y - points[0].y;
  let zSize = 3;
  let size = [xSize, zSize, ySize];

  let positionX = points[0].x + .5 * xSize;
  let positionY = points[0].y + .5 * ySize;
  let position = [positionX, floorHeight[floor], -1 * positionY];

  return (
    <mesh position={position} >
      <boxGeometry args={size}/>
      <meshStandardMaterial color={"#" + color} opacity={.4} transparent={true} visible={visible}/>
    </mesh>
  )
}

const Anchor = ({id, position, size, color, visible, label, z}) => {
  const [showLabel, setShowLabel] = useState(false);
  return (
    <mesh position={position} onClick={()=>setShowLabel(!showLabel)}>
      {(showLabel || label) && <Html><div className="label">{id}</div></Html>}
      <boxGeometry args={size}/>
      <meshStandardMaterial color={color} visible={visible}/>
    </mesh>
  )
}

const Tracker = ({id, position, size, color, visible, label}) => {
  const [showLabel, setShowLabel] = useState(false);
  const ref = useRef();

  const vec = new Vector3(position[0], position[1], position[2]);
  useFrame(() => {
    ref.current.position.lerp(vec, .01);
  });

  return (
    <mesh ref={ref} onClick={()=>setShowLabel(!showLabel)}>
      {(showLabel || label) && <Html><div className="label"><div>{id}</div>{"x:" + position[0].toFixed(1) + " y:" + -1 * position[2].toFixed(1) + " z:" + position[1].toFixed(1) }</div></Html>}
      <sphereGeometry args={size}/>
      <meshStandardMaterial color={color} visible={visible} wireframe/>
    </mesh>
  )
}

const Origin = ({position, size, color}) => {
  return (
    <mesh
      position={position}
    >
      <sphereGeometry args={size}/>
      <meshStandardMaterial color={color} wireframe/>
    </mesh>
  )
}

const useFetchToken = (url, username, password) => {
  const [data, setData] = useState(null);

  useEffect(()=>{
    console.log("use effect - get token");

    let init = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username,
          password
        })
    };

    fetch(url, init)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setData(data.token);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [url, username, password])

  return data;
}

const useFetchSite = (site, token) => {
  const [siteData, setSiteData] = useState({zoneData: {}, trackerData: {}, trackerMap: {},  trackerPos: {}, anchorData: {}});

  useEffect(() => {
    if (!site) { console.log("I have null token"); return }
    if (!token) {console.log("I have null site"); return }

    let url = MAPCONFIG[site]['API_ROOT'] + 'sites/' + site + "?detailed";
    let init = {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            "authorization": "Bearer " + token
        }
      }

      fetch(url, init)
      .then(response => {
        if (!response.ok) {
          throw new Error('FetchSite - response was not ok');
        }
        return  response.json();
      })
      .then(json => {
        //console.log(JSON.stringify(json));

        let siteData = {};
        siteData['zoneData'] = json.state.configs?.lps?.zones || {};

        let trackers = {}; //{id: name, colors, loc}
        let trackerMap = {}; //{name: id}
        let trackerPos = {}; //{id: location}
        let anchors = {}; //{id: name, connected, configs}

        for (const id in json['state']['nodes']) {
          if (json['state']['nodes'][id]['node_type'] === 'tracker') {
            trackers[id] = {
              name: json.state?.nodes?.[id].name || "",
              color: json.state?.nodes?.[id].tags?.color ? "#" + json.state.nodes[id].tags.color : "blue",
              loc: json.state?.nodes?.[id].events?.tracker?.location || {},
            }

            trackerPos[id] = [
              json.state?.nodes?.[id].events?.tracker?.location?.filtered[0] * 0.3048 || 0,
              json.state?.nodes?.[id].events?.tracker?.location?.filtered[2] * 0.3048 || 0,
              json.state?.nodes?.[id].events?.tracker?.location?.filtered[1] * -0.3048 || 0,
              json.state?.nodes?.[id].events?.tracker?.location?.timestamp || 0, //timestamp
            ]

            if (json.state?.nodes?.[id].name) {
              trackerMap[json.state.nodes[id].name] = id;
            }
          }

          if (json['state']['nodes'][id]['node_type'] === 'anchor') {
            anchors[id] = {
              name: json.state?.nodes?.[id].name || "",
              connected: json.state?.nodes?.[id].events?.system?.connection?.connected || false,
              configs: json.state?.nodes?.[id].configs?.anchor || {},
              x: json.state?.nodes?.[id].configs?.anchor?.x / 100 * 0.3048 || 0,
              y: json.state?.nodes?.[id].configs?.anchor?.y / 100 * 0.3048 || 0,
              z: json.state?.nodes?.[id].configs?.anchor?.z / 100 * 0.3048 || 0,
            }
          }
        }

        siteData['trackerData'] = trackers;
        siteData['trackerPos'] = trackerPos;
        siteData['trackerMap'] = trackerMap;
        siteData['anchorData'] = anchors;

        setSiteData(siteData);
      })
      .catch(error => {
        console.error("Error: " + error)
      })

    return () => {
      console.log("Fin - SetSiteData")
    }
  }, [site, token])

  return siteData;
}

const useFetchPath = (token, site, trackerData, startDate, startTime, endDate, endTime, showPath) => {
  const [pathData, setPathData ] = useState({}); //{id: [x,z,- y]}

  useEffect(() => {
    setPathData({});

    if (!showPath) { console.log("fetchPath - showPath is false"); return; }
    if (!site) { console.log("fetchPath - I have no site"); return; }
    if (!token) { console.log("fetchPath - I have no token"); return; }
    if (!startDate || !endDate || !startTime || !endTime) { console.log("fetchPath - wrong start and end date and time"); return; }

    //convert from and to here
    let start = new Date(startDate.date)
    start.setHours(0,0,0,0);
    let [sHour, sMin] = startTime.split(":");
    let sTime = start.getTime() + (parseInt(sHour) * 3600000) + (parseInt(sMin) * 60000);

    let end = new Date(endDate.date)
    end.setHours(0,0,0,0);
    let [eHour, eMin] = endTime.split(":")
    let eTime = end.getTime() + (parseInt(eHour) * 3600000) + (parseInt(eMin) * 60000);

    console.log("start path query ... ")
    let url = MAPCONFIG[site]['API_ROOT'] + 'sites/' + site + '/lps-wms-stats';
    let body = {
      "time_intervals" : [{from: sTime, to: eTime}], //[{from: 1709357108876, to: 1709400308876}],
      "assets": Object.keys(trackerData),
      "use_wms" : false,
      "outputs" : { "locations": {floor: "1"}}
    }

    let init = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + token
        },
        body: JSON.stringify(body)
      };

    fetch(url, init)
    .then(response => {
      if (!response.ok) {
        throw new Error('FetchPath - response was not ok');
      }
      return  response.json();
    })
    .then(json => {
      //console.log(json);
      let pathData = {} //{id: [[x, z, -y], []]}
      for (const nodeId in json.assets) {
        json.assets[nodeId].locations.forEach((locArray)  => {
          if (!pathData.hasOwnProperty(nodeId)) {
            pathData[nodeId] = [];
          }

          locArray.forEach(loc => {
            pathData[nodeId].push([loc.point.x * 0.3048,loc.point.z * 0.3048,loc.point.y *  -0.3048]);
          })
        })
      }
      setPathData(pathData);
      console.log("end path query ...")
    })
    .catch(error => {
      console.error("Error: setPathData" + error);
    })

    return() => {
        console.log("Fin - setPathData")
    }
  }, [token, site, trackerData, startDate, startTime, endDate, endTime, showPath])

  return pathData;
}

//'wss://echo.websocket.org/'
const useWebSockets = (token, site) => {
  const[data, setData] = useState({});

  useEffect(() => {
    if (!token) { console.log("I have null token"); return }
    if (!site) { console.log("I have null site"); return }

    const url = MAPCONFIG[site]['WS_ROOT'] + site + "?token=" + token + "&event=tracker:location";
    const websocket = new WebSocket(url);

    websocket.onopen = () => {
      console.log('connected ' + url);
    }

    websocket.onmessage = throttle((event) => {
      const data = JSON.parse(event.data);
      let location = [
        0.3048 * data.update.events.tracker.location.filtered[0],
        0.3048 * data.update.events.tracker.location.filtered[2],
        -0.3048 * data.update.events.tracker.location.filtered[1],
        data.update.events.tracker.location.timestamp //timestamp
      ]
      setData({[data.id] : location});
    }, 250);

    return () => {
      console.log("closing socket ")
      websocket.close()
    }
  }, [token, site]);

  return data;
}

const MapScene = () => {
  const [site, setSite] = useState("f6aa5b0283114de6a3d8933bebc6dc4a");
  const token = useFetchToken(MAPCONFIG[site]['API_ROOT'] + 'authenticate', MAPCONFIG[site]['USERNAME'], MAPCONFIG[site]['PASSWORD'] );
  const siteData = useFetchSite(site, token);
  const [trackerPos, setTrackerPos] = useState(siteData['trackerPos']); //{id: [x,y,z]}
  const socketData = useWebSockets(token, site);
  const directionalLightRef = useRef();
  const cameraRef = useRef();

  useEffect(()=> {
    //console.log("useeffect - siteData")
    setTrackerPos(siteData['trackerPos']);
  }, [siteData])

  useEffect(() => {
    //console.log(JSON.stringify(socketData));
    setTrackerPos(pos => Object.assign(pos, socketData));
  }, [socketData])

  //https://leva.pmnd.rs/?path=/story/misc-controlled-inputs--external-updates-with-set
  //https://github.com/pmndrs/leva/blob/main/docs/advanced/controlled-inputs.md
  //https://github.com/pmndrs/leva/issues/184
  //https://github.com/pmndrs/leva/blob/main/docs/getting-started.md
  const [{anchorVisible, anchorLabel, anchorSize, trackerVisible, trackerLabel, trackerSize, zoneVisible, floor, view }, set] = useControls('LIVE', () =>({
    site: {
      options: Object.keys(MAPCONFIG),
      onChange: (value) => {
        setSite(value);
        set({sitename: MAPCONFIG[value]['SITENAME']});
        set2({showPath: false});
      }
    },
    sitename: MAPCONFIG[site]['SITENAME'],
    anchorVisible: true,
    anchorLabel: false,
    anchorSize: {
      value: .3,
      min: .1,
      max: 1,
      step: .1
    },
    trackerVisible: true,
    trackerLabel: false,
    trackerSize: {
      value: .2,
      min: .1,
      max: 1,
      step: .1
    },
    zoneVisible: false,
    view: {options: ["FULL", "TOP", "SIDE", "FLAT", "FLIP"]},
    floor: {options: ["DEFAULT", "GROUND", "MEZZ", "ROOF"]},
  }));

  const [{startDate, startTime, endDate, endTime, tracker, showPath, pathSize}, set2, get2] = useControls('HISTORICAL', () => {
    return ({
      startDate: date({
        date: new Date(),
        inputFormat: 'MM/dd/yyyy',
        onChange: (value) => {
          set2({showPath: false});
        }
      }),
      startTime: {
        value: "08:00",
        onChange: (value) => {
          set2({showPath: false});
          console.log("startTime")
        }
      },
      endDate: date({
        date: new Date(),
        inputFormat: 'MM/dd/yyyy',
        onChange: (value) => {
          set2({showPath: false});
        }
      }),
      endTime: {
        value: "09:00",
        onChange: (value) => {
          set2({showPath: false});
        }
      },
      showPath: false,
      pathSize: {
        value: 2,
        min: 1,
        max: 10,
        step: 1
      },
      tracker: {
        options: ["ALL"].concat(Object.keys(siteData.trackerMap).sort())
      },
    })
  }, [siteData]);

  const [{lightColor, lightIntensity}]  = useControls('SETTING', () => ({
    lightColor: "white",
    lightIntensity: {
      value: 2.0,
      min: 0,
      max: 10,
      step: .5
    }
  }));

  const pathData = useFetchPath(token, site, siteData.trackerData, get2('startDate'), get2('startTime'), get2('endDate'), get2('endTime'), showPath);

  //useHelper(directionalLightRef, DirectionalLightHelper, .1, "white");
  //useHelper(cameraRef, CameraHelper, 100, 'red')

  return (
    <>
      {<directionalLight position={[0,0,3]} intensity={lightIntensity} ref={directionalLightRef} color={lightColor} />}
      <ambientLight intensity={1}/>
      {/*<PerspectiveCamera ref={cameraRef} />*/}
        <group
          rotation={MAPCONFIG[site].MAPVIEW[view].rotation}
          scale={MAPCONFIG[site].MAPVIEW[view].scale}
          position={MAPCONFIG[site].MAPVIEW[view].position}
        >
        {<ColladaTest map={MAPCONFIG[site].MAPFILE[floor]}/>}
        {<Origin position={[0,0,0]} size={[.15,30,30]} color={"red"} visible={anchorVisible}/>}
        {Object.values(siteData['zoneData']).map((z, index) =>
          <Zone key={index}
                color={z.color}
                floor = {z.floor}
                points={z.points}
                visible={zoneVisible}
        />)}

        {Object.values(MAPCONFIG[site].ANCHORS).map((a, index) => <Anchor key={index} position={[a[0], a[2], (a[1] * -1)]} size={[.5, .5, .5]} color={"red"} visible={anchorVisible}/>)}



        {Object.values(Object.values(siteData.anchorData)).map((a, index) =>
            <Anchor
                key={a.name}
                id={a.name}
                position={[a.x, a.z, (a.y * -1)]}
                size={[anchorSize,anchorSize,anchorSize]}
                color={a.connected? "green" : "red"}
                visible={a.z >= MAPCONFIG[site].FLOOR_Z[floor].z_min && a.z < MAPCONFIG[site].FLOOR_Z[floor].z_max ? anchorVisible : false}
                label={a.z >= MAPCONFIG[site].FLOOR_Z[floor].z_min && a.z < MAPCONFIG[site].FLOOR_Z[floor].z_max ?anchorLabel : false}
              />)}
        {Object.entries(trackerPos).map((p) =>
            <Tracker
                key={p[0]}
                id={siteData.trackerData[p[0]] && siteData.trackerData[p[0]].name}
                position={p[1]}
                size={[trackerSize,30,30]}
                color={Date.now() - p[1][3] >  600000 ? "grey" : "purple"}
                visible={p[1][1] >= MAPCONFIG[site].FLOOR_Z[floor].z_min && p[1][1] < MAPCONFIG[site].FLOOR_Z[floor].z_max ? trackerVisible : false}
                label={p[1][1] >= MAPCONFIG[site].FLOOR_Z[floor].z_min && p[1][1] < MAPCONFIG[site].FLOOR_Z[floor].z_max ? trackerLabel : false}
            />)}
        {
          Object.entries(pathData).map((b) => <Line key={b[0]} points={b[1]} color={siteData.trackerData[b[0]]?.color } lineWidth={pathSize} visible={tracker === "ALL" || b[0] === siteData.trackerMap[tracker] ? true : false}/>)
        }
        {/*console.log(JSON.stringify("Pathdata - " + JSON.stringify(pathData)))*/}
        {/*console.log(get2('startDate'))*/}
        <OrbitControls
          maxDistance={7}
          minAzimuthAngle={-.35}
          maxAzimuthAngle={.35}
          minPolarAngle={Math.PI/3}
          maxPolarAngle={Math.PI/2}
        />
      </group>
    </>
  )
}

const Map = () => {
  return (
    <div style={{height: "100vh"}}>
      <Canvas>
        <MapScene/>
      </Canvas>
    </div>
  )
}

export default Map;

//https://zps-test.b-cdn.net/0b897ff8-77aa-42d9-a516-7afdc1d1b198.dae

/*
<Text
  scale={[5, 5, 5]}
  position={[-1,8,0]}
  color="black" // default
  anchorX="left" // default
  anchorY="left" // default
>
  Belmont Warehouse
</Text>
*/

//https://www.youtube.com/watch?v=FwcXultcBl4
//https://stackoverflow.com/questions/16226693/three-js-show-world-coordinate-axes-in-corner-of-scene
//https://threejs.org/docs/#examples/en/controls/OrbitControls
//https://www.youtube.com/watch?v=qpOZup_3P_A
//https://www.youtube.com/watch?v=R0954J4PU7w
//https://www.youtube.com/watch?v=zSvVwCYwKM8
//https://sbcode.net/react-three-fiber/leva/

//https://sbcode.net/react-three-fiber/camera/
//https://sbcode.net/react-three-fiber/camera/

//buttons - https://codesandbox.io/p/sandbox/suspicious-bessie-lo6dx3?file=%2Fsrc%2FApp.js

/*
<Line
  points={[[0, 0, 0], ...]}       // Array of points, Array<Vector3 | Vector2 | [number, number, number] | [number, number] | number>
  color="black"                   // Default
  lineWidth={1}                   // In pixels (default)
  segments                        // If true, renders a THREE.LineSegments2. Otherwise, renders a THREE.Line2
  dashed={false}                  // Default
  vertexColors={[[0, 0, 0], ...]} // Optional array of RGB values for each point
  {...lineProps}                  // All THREE.Line2 props are valid
  {...materialProps}              // All THREE.LineMaterial props are valid
/>

https://leva.pmnd.rs/?path=/story/misc-panel-options--hide-copy-button


  /*const[{}, set3] = useControls('C', () => ({
    Size: 1,
    ' ': buttonGroup({
      '0.25x': () => set3({ Size: 0.25 }),
      '0.5x': () => set3({ Size: 0.5 }),
      '1x': () => set3({ Size: 1 }),
      '2x': () => set3({ Size: 2 }),
      '3x': () => set3({ Size: 3 }),
    }),
  }));

    <Line
    key={}
    points={[[0, 0, 0], [24,2,-2], [31, 0, -12]]}       // Array of points, Array<Vector3 | Vector2 | [number, number, number] | [number, number] | number>
    color="purple"                   // Default
    lineWidth={3}                   // In pixels (default)
    />
*/
