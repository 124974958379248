import React from 'react';
import ReactDOM from 'react-dom/client';
import 'mapbox-gl/dist/mapbox-gl.css';
import './index.css';
//import App from './App';
//import Map from './3dMap';
import Map from './map9.js';
//import BelmontMap from './3dMapBelmont.js';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Map />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/*
Wifi-Prd-Jpn 9/13/23
API_ROOT = https://api.wifi-prd-jpn.zainar.net/pipeline/r/api/v2/client
WS_ROOT = 'wss://api.wifi-prd-jpn.zainar.net/pipeline/ws/updates/v2/sites/';
Super User: admin
Password: !}1dWB@$jbTk[p$k
*/
