/* DEFAULT MAPFILE
"DEFAULT": "DefaultMap.dae",
SIDE: {
  rotation: [1, 0, 0],
  scale: 0.16,
  position: [-7, -3.5, 0],
},
TOP: {
  rotation: [1.555, 0, 0],
  scale: 0.1,
  position: [-5.6, -3, 0],
},
FULL: {
  rotation: [1, 0, 0],
  scale: 0.16,
  position: [-7, -3.5, 0],
},
*/

//Map Config for Kajima
export const MAPCONFIG = {
  "a0536dbd1ce444e7a844b632cb7d1447" : { //Kajima-Kobe - Kobe Ice Arena
    API_ROOT : 'https://api.wifi-prd-jpn.zainar.net/pipeline/r/api/v2/client/',
    WS_ROOT : 'wss://api.wifi-prd-jpn.zainar.net/pipeline/ws/updates/v2/sites/',
    USERNAME : "admin",
    PASSWORD : "!}1dWB@$jbTk[p$k",
    SITENAME: "Kobe Ice Arena",
    MAPFILE: {
      "DEFAULT": "20241114_Kobe_AllFloors.dae",
      "GROUND": "20241114_Kobe_1stFloor.dae",
      "MEZZ":  "20241114_Kobe_2nd3rdFloor.dae",
      "ROOF":  "20241114_Kobe_2nd3rdFloor.dae"
    },
    FLOOR_Z: {
      "DEFAULT": {z_min: 0, z_max: 100},
      "GROUND": {z_min: 0, z_max: 3.75},
      "MEZZ":  {z_min: 5, z_max: 9.08},
      "ROOF":  {z_min: 9.8, z_max: 13.94}
    },
    MAPVIEW: {
      SIDE: {
        rotation: [0, 0, 0],
        scale: 0.18,
        position: [-4, 0, 0],
      },
      TOP: {
        rotation: [1.555, 0, 0],
        scale: 0.075,
        position: [-2, -2.6, 0],
      },
      FULL: {
        rotation: [1, 0, 0],
        scale: 0.125,
        position: [-3, -3, 0],
      },
      FLAT: {
        rotation: [0.25, 0, 0],
        scale: .35,
        position: [-9, -1, 0],
      },
      FLIP: {
        rotation: [1.1, Math.PI, 0],
        scale: .15,
        position: [4, 2.5, 0],
      },
    },
    ANCHORS : {
    }
  },
  "f6aa5b0283114de6a3d8933bebc6dc4a" : {
    API_ROOT : 'https://api.wifi-prd-jpn.zainar.net/pipeline/r/api/v2/client/',
    WS_ROOT : 'wss://api.wifi-prd-jpn.zainar.net/pipeline/ws/updates/v2/sites/',
    USERNAME : "admin",
    PASSWORD : "!}1dWB@$jbTk[p$k",
    SITENAME: "Belmont_Trackers_Demo",
    MAPFILE: {
      //"DEFAULT": "7c46b381-e669-48c0-9bea-e216c0dd808e.dae",
      //"MULTI": "BelmontProd.dae"
      "DEFAULT": "f74ea296-7d4a-48db-b975-5674d732d2d7.dae",
      "GROUND": "7c46b381-e669-48c0-9bea-e216c0dd808e-0.dae",
      "MEZZ":  "65880153-f21a-4a9d-8395-5274c37f3e4d.dae",
      "ROOF":  "27d18f7f-411d-4e02-84ef-95919df95518.dae",
    },
    FLOOR_Z: {
      "DEFAULT": {z_min: 0, z_max: 100},
      "GROUND": {z_min: 0, z_max: 3.5},
      "MEZZ":  {z_min: 3.5, z_max: 7.5},
      "ROOF":  {z_min: 7.5, z_max: 100},
    },
    MAPVIEW: {
      /* for orbital control
      SIDE : {
        rotation: [.5,0,0],
        scale: .5,
        position:[-14,-9,1]
      },
      TOP : {
        rotation: [.78,0,0],
        scale: .4,
        position:[-11,-7.5,1]
      },
      FULL : {
        rotation: [.25,0,0],
        scale: .6,
        position: [-12,-18,0]
      },*/

      FLAT: {
        rotation: [0.25, 0, 0],
        scale: .35,
        position: [-9, -1, 0],
      },
      SIDE: {
        rotation: [1.1, 0, 0],
        scale: 0.23,
        position: [-6.3, -3, 0],
      },
      TOP: {
        rotation: [1.555, 0, 0],
        scale: 0.205,
        position: [-5.6, -3, 0],
      },
      FULL: {
        rotation: [1, 0, 0],
        scale: 0.16,
        position: [-4, -3.5, 0],
      },
      FLIP: {
        rotation: [1.1, Math.PI, 0],
        scale: .15,
        position: [4, 2.5, 0],
      },
    },
    ANCHORS : {
      /*
      'anchor_cf08' : [39.67,17.54,5.66],
      'anchor_cf2d' : [31.89,18.78,3.8],
      'anchor_d0ef_office' : [27.6,0.6,2],
      'anchor_d111_office' : [27.6,6.35,2.11],
      'anchor_d113' : [54.59,10,2.8],
      'anchor_d114' : [17.86,30.69,3.8],
      'anchor_d130' : [43.99,9,3.2],
      'anchor_d137' : [7,20.2,3.15],
      'anchor_d139' : [31.75,30.69,3.8],
      'anchor_d13e' : [18.29,23.79,3.15],
      'anchor_d141_mezz' : [21.64,10.5,5.38],
      'anchor_d148' : [31.99,10.98,1.83],
      'anchor_d1d7' : [44,30.69,3.8],
      'anchor_d1ee' : [4.85,29,3.15],
      'anchor_d1f2' : [54.59,27.31,2.8],
      'anchor_d1f3' : [54.59,19.99,1.53],
      'anchor_d217_office' : [45.5,0.6,2.35]
      */
    }
  },
  "7133dbb1bc3d43d79ef5e720e577f8aa" : {
    API_ROOT : 'https://api.wifi-dev.zainar.net/pipeline/r/api/v2/client/',
    WS_ROOT : 'wss://api.wifi-dev.zainar.net/pipeline/ws/updates/v2/sites/',
    USERNAME : "admin",
    PASSWORD : '{@]eA-m{BxnB8%i0',
    SITENAME: "Belmont-Dev-Test-Warehouse",
    MAPFILE: {
      "DEFAULT": "f74ea296-7d4a-48db-b975-5674d732d2d7.dae",
      "GROUND": "7c46b381-e669-48c0-9bea-e216c0dd808e-0.dae",
      "MEZZ":  " 65880153-f21a-4a9d-8395-5274c37f3e4d.dae",
      "ROOF":  "27d18f7f-411d-4e02-84ef-95919df95518.dae",
    },
    FLOOR_Z: {
      "DEFAULT": {z_min: 0, z_max: 100},
      "GROUND": {z_min: 0, z_max: 3.5},
      "MEZZ":  {z_min: 3.5, z_max: 7.5},
      "ROOF":  {z_min: 7.5, z_max: 100},
    },
    MAPVIEW: {
      FLAT: {
        rotation: [0.25, 0, 0],
        scale: .35,
        position: [-9, -1, 0],
      },
      SIDE: {
        rotation: [1.1, 0, 0],
        scale: 0.23,
        position: [-6.3, -3, 0],
      },
      TOP: {
        rotation: [1.555, 0, 0],
        scale: 0.205,
        position: [-5.6, -3, 0],
      },
      FULL: {
        rotation: [1, 0, 0],
        scale: 0.16,
        position: [-4, -3.5, 0],
      },
      FLIP: {
        rotation: [1.1, Math.PI, 0],
        scale: .15,
        position: [4, 2.5, 0],
      },
    },
    ANCHORS : {
    }
  },
  "677f75cdfac4485697128fd6f1698df1" : {
    API_ROOT : 'https://api.wifi-dev.zainar.net/pipeline/r/api/v2/client/',
    WS_ROOT : 'wss://api.wifi-dev.zainar.net/pipeline/ws/updates/v2/sites/',
    USERNAME : "admin",
    PASSWORD : '{@]eA-m{BxnB8%i0',
    SITENAME: "Belmont-5G-Dev",
    MAPFILE: {
      "DEFAULT": "f74ea296-7d4a-48db-b975-5674d732d2d7.dae",
      "GROUND": "7c46b381-e669-48c0-9bea-e216c0dd808e-0.dae",
      "MEZZ":  " 65880153-f21a-4a9d-8395-5274c37f3e4d.dae",
      "ROOF":  "27d18f7f-411d-4e02-84ef-95919df95518.dae",
    },
    FLOOR_Z: {
      "DEFAULT": {z_min: 0, z_max: 100},
      "GROUND": {z_min: 0, z_max: 3.5},
      "MEZZ":  {z_min: 3.5, z_max: 7.5},
      "ROOF":  {z_min: 7.5, z_max: 100},
    },
    MAPVIEW: {
      FLAT: {
        rotation: [0.25, 0, 0],
        scale: .35,
        position: [-9, -1, 0],
      },
      SIDE: {
        rotation: [1.1, 0, 0],
        scale: 0.23,
        position: [-6.3, -3, 0],
      },
      TOP: {
        rotation: [1.555, 0, 0],
        scale: 0.205,
        position: [-5.6, -3, 0],
      },
      FULL: {
        rotation: [1, 0, 0],
        scale: 0.16,
        position: [-4, -3.5, 0],
      },
      FLIP: {
        rotation: [1.1, Math.PI, 0],
        scale: .15,
        position: [4, 2.5, 0],
      },
    },
    ANCHORS : {
    }
  },
  /*
  4220 m wide , 13845.14ft
  2032 m high, 6666.667ft
  3 m to 9.8 ft
  6 m to 20 ft

  '12b344e80f1347e088f16fe679202127' : { //Fukuoka_kajima_kstation
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    map_order: ['1'],
    maps: {
      '1' : {
        background_src: 'Kajima2D.png',
        small: {
          width: 1055,
          height: 508.26,
          pixels_per_meter: 3.95,
        },
        origin: {x: 0, y: 0.1}
      }
    },
  },
  */
  "12b344e80f1347e088f16fe679202127" : {
    API_ROOT : 'https://api.wifi-prd-jpn.zainar.net/pipeline/r/api/v2/client/',
    WS_ROOT : 'wss://api.wifi-prd-jpn.zainar.net/pipeline/ws/updates/v2/sites/',
    USERNAME : "admin",
    PASSWORD : "!}1dWB@$jbTk[p$k",
    SITENAME: "Fukuoka_Kajima_KStation",
    MAPFILE: {
      DEFAULT: "68a965a7-caea-435a-bb47-85e041e00f55.dae",
      GROUND: "68a965a7-caea-435a-bb47-85e041e00f55.dae",
      MEZZ: "0b00bb23-e18a-4eaa-91b5-b3fc7b5ea43e.dae",
      ROOF: "0b00bb23-e18a-4eaa-91b5-b3fc7b5ea43e.dae",
    },
    FLOOR_Z: {
      "DEFAULT": {z_min: 0, z_max: 100},
      "GROUND": {z_min: 0, z_max: 3},
      "MEZZ":  {z_min: 3, z_max: 6},
      "ROOF":  {z_min: 6, z_max: 10},
    },
    MAPVIEW: {
      /*SIDE : {
        rotation: [1.2,0,0],
        scale: .2,
        position:[-35,-12,0],
      },
      TOP : {
        rotation: [1.555,0,0],
        scale: .08,
        position: [-15,-6,0]
      },
      FULL : {
        rotation: [.4,0,0],
        scale: .6,
        position:[-105,-40,10],
      },
      FLIP : {
        rotation: [1,-2.5,0],
        scale: .2,
        position:[13,0,-5],
      },*/
      FLAT: {
       rotation: [1.2, 0, 0],
       scale: 0.2,
       position: [-35, -12, 0],
     },
      SIDE: {
       rotation: [1.2, 0, 0],
       scale: 0.2,
       position: [-35, -12, 0],
     },
     TOP: {
       rotation: [1.555, 0, 0],
       scale: 0.08,
       position: [-15, -6, 0],
     },
     FULL: {
       rotation: [1.2, 0, 0],
       scale: 0.1,
       position: [-17.5, -6, 0],
     },
     FLIP: {
       rotation: [.55, Math.PI, 0],
       scale: .2,
       position: [33,10,-20]
     },
    },
    ANCHORS: {
      /*
      'Anchor_1' : [82.5,	88.34,	2],
      'Anchor_2' : [110.85,	100.33,	2],
      'Anchor_3':	[140.23,	90.42,	2],
      'Anchor_4':	[167.13,	91.21,	2],
      'Anchor_5':	[166.97,	120.43,	2],
      'Anchor_6':	[208.83,	120.98,	2],
      'Anchor_7':	[216.67,	100.57,	2],
      'Anchor_8':	[188.41,	89.65,	2],
      'Anchor_9': [224.47,	80.26,	2],
      'Anchor_10':	[263.77,	80.03,	2],
      'Anchor_11':	[260.16,	46.41,	2],
      'Anchor_12':	[224.68,	41.61,	2],
      'Anchor_13':	[186.32,	29.63,	2],
      'Anchor_14':	[152.14,	47.52,	2],
      'Anchor_15':	[112.75,	48.5,	2],
      'Anchor_16':	[84.36,	52.93,	2],
      'Anchor_17':	[122.35,	75.46,	2.75],
      'Anchor_18':	[142.35,	75.86,	2.75],
      'Anchor_19':	[162.35,	75.96,	2.75],
      'Anchor_20':	[176.7,	72.49,	2.75],
      'Anchor_21':	[192.35,	75.96,	2.75],
      'Anchor_22':	[212.35,	68.96,	2.75],
      'Anchor_23':	[232.35,	68.96,	2.75],
      'Anchor_24':	[232.35,	50.62,	2.75],
      'Anchor_25':	[212.35,	50.26,	2.75],
      'Anchor_26':	[192.34,	50.16,	2.75],
      'Anchor_27':	[177.99,	53.64,	2.75],
      'Anchor_28':	[162.34,	50.16,	2.75],
      'Anchor_29':	[142.34,	57.16,	2.75],
      'Anchor_30':	[122.35,	57.16,	2.75],
      'Anchor_31':	[157.99,	63.06,	2.75],
      'Anchor_32':	[196.7,	63.06,	2.75],
      //"EndPoint" : [266.8, 128.88, 0] //end point
      */
    },
  },
  "bb270b27478b435cbadec165809783ed" : {
    API_ROOT : 'https://api.wifi-prd-jpn.zainar.net/pipeline/r/api/v2/client/',
    WS_ROOT : 'wss://api.wifi-prd-jpn.zainar.net/pipeline/ws/updates/v2/sites/',
    USERNAME : "admin",
    PASSWORD : "!}1dWB@$jbTk[p$k",
    SITENAME: "Kasugabaru Station",
    MAPFILE: {
      DEFAULT:  "kstation2ndfoorwLabels.dae",//"kstationallfloorcolorsworginV4.dae", //"KstationOptimized3.dae",
      GROUND: "kstation1stfloorwLabels.dae", //kstation1stfloorfinalcolorsworginLightGrey.dae",
      MEZZ: "kstation2ndFloorwLabels1.dae", //"kstation2ndfoorfinalcolorsworginV1.dae",
      ROOF: "kstation2ndFloorwLabels1.dae", //"kstation2ndfoorfinalcolorsworginV1.dae",//"Kstation2ndFloorOptimized1dae.dae",
    },
    FLOOR_Z: {
      "DEFAULT": {z_min: 0, z_max: 100},
      "GROUND": {z_min: 0, z_max: 3},
      "MEZZ":  {z_min: 3, z_max: 6},
      "ROOF":  {z_min: 6, z_max: 10},
    },
    MAPVIEW: {
      FLAT: {
       rotation: [1.2, 0, 0],
       scale: 0.2,
       position: [-35, -12, 0],
     },
      SIDE: {
       rotation: [1.2, 0, 0],
       scale: 0.2,
       position: [-35, -12, 0],
     },
     TOP: {
       rotation: [1.555, 0, 0],
       scale: 0.08,
       position: [-15, -6, 0],
     },
     FULL: {
       rotation: [1.2, 0, 0],
       scale: 0.1,
       position: [-17.5, -6, 0],
     },
     FLIP: {
       rotation: [.55, Math.PI, 0],
       scale: .2,
       position: [33,10,-20]
     },
    },
    ANCHORS: {
      'Anchor_1' : [82.5,	88.34,	2],
      'Anchor_2' : [110.85,	100.33,	2],
      'Anchor_3':	[140.23,	90.42,	2],
      'Anchor_4':	[167.13,	91.21,	2],
      'Anchor_5':	[166.97,	120.43,	2],
      'Anchor_6':	[208.83,	120.98,	2],
      'Anchor_7':	[216.67,	100.57,	2],
      'Anchor_8':	[188.41,	89.65,	2],
      'Anchor_9': [224.47,	80.26,	2],
      'Anchor_10':	[263.77,	80.03,	2],
      'Anchor_11':	[260.16,	46.41,	2],
      'Anchor_12':	[224.68,	41.61,	2],
      'Anchor_13':	[186.32,	29.63,	2],
      'Anchor_14':	[152.14,	47.52,	2],
      'Anchor_15':	[112.75,	48.5,	2],
      'Anchor_16':	[84.36,	52.93,	2],
      'Anchor_17':	[122.35,	75.46,	2.75],
      'Anchor_18':	[142.35,	75.86,	2.75],
      'Anchor_19':	[162.35,	75.96,	2.75],
      'Anchor_20':	[176.7,	72.49,	2.75],
      'Anchor_21':	[192.35,	75.96,	2.75],
      'Anchor_22':	[212.35,	68.96,	2.75],
      'Anchor_23':	[232.35,	68.96,	2.75],
      'Anchor_24':	[232.35,	50.62,	2.75],
      'Anchor_25':	[212.35,	50.26,	2.75],
      'Anchor_26':	[192.34,	50.16,	2.75],
      'Anchor_27':	[177.99,	53.64,	2.75],
      'Anchor_28':	[162.34,	50.16,	2.75],
      'Anchor_29':	[142.34,	57.16,	2.75],
      'Anchor_30':	[122.35,	57.16,	2.75],
      'Anchor_31':	[157.99,	63.06,	2.75],
      'Anchor_32':	[196.7,	63.06,	2.75],
      "EndPoint" : [266.8, 128.88, 0] //end point
    },
  },
  "38df7d876e454d10bc89b0b8a32fd438" : { //kotopump
    API_ROOT : 'https://api.wifi-prd-jpn.zainar.net/pipeline/r/api/v2/client/',
    WS_ROOT : 'wss://api.wifi-prd-jpn.zainar.net/pipeline/ws/updates/v2/sites/',
    USERNAME : "admin",
    PASSWORD : "!}1dWB@$jbTk[p$k",
    SITENAME: "Koto Pumping Station",
    MAPFILE: {
      "DEFAULT": "KotoPumpWLabels.dae", //"KotoPumpNewOrginA1Intersectionv1.dae", /*"KotoPumpOptimized2.dae",*/
      "GROUND": "KotoPumpWLabels.dae", //"KotoPumpOptimized2.dae",
      "MEZZ":  "KotoPumpWLabels.dae", //"KotoPumpOptimized2.dae",
      "ROOF":  "KotoPumpWLabels.dae", //"KotoPumpOptimized2.dae",
    },
    FLOOR_Z: {
      "DEFAULT": {z_min: 0, z_max: 100},
      "GROUND": {z_min: 0, z_max: 3.5},
      "MEZZ":  {z_min: 3.5, z_max: 7.5},
      "ROOF":  {z_min: 7.5, z_max: 100},
    },
    MAPVIEW: {
      SIDE: {
        rotation: [0, 0, 0],
        scale: .11,
        position: [-3,0,0],
      },
      TOP: {
        rotation: [1.555, 0, 0],
        scale: .075,
        position: [-2, -2.75, 0],
      },
      FULL: {
        rotation: [1.1, 0, 0],
        scale: .11,
        position: [-3,-3,0],
      },
      /* old
      SIDE: {
        rotation: [1.1, 0, 0],
        scale: .8,
        position: [-20, -7, 0],
      },
      TOP: {
        rotation: [1.555, 0, 0],
        scale: .22,
        position: [-6, -8, 0],
      },
      FULL: {
        rotation: [1.1, 0, 0],
        scale: .8,
        position: [-20, -7, 0],
      },*/
      FLIP: {
        rotation: [1.1, Math.PI, 0],
        scale: .12,
        position: [3, 7, 0],
      },
      FLAT: {
        rotation: [0.8, 0, 0],
        scale: 1,
        position: [-25, 10, 0],
      },
    },
    ANCHORS: {
      //"barbara" : [8.5344, 39.0144, 2]
      //"EndPoint" : [266.8, 128.88, 0] //end point
    },
  },
}

export const CLOUDCONFIG = {
  API_ROOT : 'https://api.wifi-prd-jpn.zainar.net/pipeline/r/api/v2/client/',
  WS_ROOT : 'wss://api.wifi-prd-jpn.zainar.net/pipeline/ws/updates/v2/sites/',
  USERNAME : "admin",
  PASSWORD : "!}1dWB@$jbTk[p$k"
}
